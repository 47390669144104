<template>
    <div id="Doctrine">
        <section id="doctrine-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">貴き親理のお授け</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-3.jpg'),
                        require('@/assets/background/background-3-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="doctrine-main" class="b25 b13-mb">
            <header class="w90 w80-mb article-style gold">
                <h2 class="f29 hide-mb">貴き親理のお授け</h2>
                <p v-html="wrap(doctrineIntro)" class="f14 f12-mb"></p>
            </header>
            <main class="article-group">
                <!-- <article
                    v-for="(item, index) in doctrineArticles"
                    :key="'doctrine-article-' + index"
                    class="article-style gold w90 w80-mb"
                >
                    <h3
                        v-if="item.title"
                        v-html="wrap(item.title)"
                        class="title w60 f29 f16-mb"
                    ></h3>
                    <p
                        v-if="item.article"
                        v-html="wrap(item.article)"
                        class="article w80 f14 f12-mb"
                    ></p>
                </article> -->
                <article class="article-style w90 w80-mb youtube-container">
                    <p class="youtube-description f14 f12-mb w80 w70-mb">
                        Youtubeの「大自然界」公式チャンネルにて、『大親神大生命』の貴き親理のお授けの一部を頂く事が出来ます。
                    </p>
                    <div class="youtube-group">
                        <div class="youtube-wrap">
                            <LiteYouTubeEmbed id="5LYKvmW3AHQ" title="" />
                        </div>
                        <div class="youtube-wrap">
                            <a
                                href="https://www.youtube.com/playlist?list=PLzXP1iBFNc4E9OLeY4NVO0i_yYuz5S-Ge"
                                target="_blank"
                            >
                                <img
                                    :src="
                                        require('@/assets/doctrine/doctrine-1.jpg')
                                    "
                                />
                            </a>
                        </div>
                    </div>
                </article>
            </main>
        </section>
        <section id="doctrine-rules" class="b25 b13-mb">
            <ul class="w80-mb" id="doctrine-rules-selector">
                <li
                    v-for="(item, index) of doctrineRules"
                    :key="'doctrine-rules-' + index"
                    class="f14 f14-mb"
                >
                    <i class="icon-right f12 f12-mb"></i>
                    <span @click="changeArticle(index)">{{ item.rule }}</span>
                </li>
            </ul>
            <main class="article-group" id="doctrine-rules-article">
                <article class="article-style gold w70 w80-mb">
                    <h3
                        v-html="wrap(doctrineRules[activeRuleId].rule)"
                        class="title f29 f16-mb"
                    ></h3>
                    <p
                        v-if="doctrineRules[activeRuleId].mainArticle"
                        v-html="wrap(doctrineRules[activeRuleId].mainArticle)"
                        class="article f14 f12-mb"
                    ></p>
                    <ul
                        v-if="doctrineRules[activeRuleId].hasLink"
                        class="jump-list-style gold"
                    >
                        <li
                            v-for="(item, index) of doctrineRules[activeRuleId]
                                .articles"
                            :key="'doctrine-rule-article-link-' + index"
                            class="f14 f12-mb"
                            @click="
                                scrollToElement(
                                    '#doctrine-rule-article-' + index
                                )
                            "
                        >
                            <i class="icon-page-link f12 f12-mb"></i
                            >{{ ' ' + item.title }}
                        </li>
                    </ul>
                </article>
                <article
                    v-for="(item, index) in doctrineRules[activeRuleId]
                        .articles"
                    :key="'doctrine-rule-article-' + index"
                    class="article-style gold w70 w80-mb"
                    :id="'doctrine-rule-article-' + index"
                >
                    <h3
                        v-if="item.title"
                        v-html="wrap(item.title)"
                        class="title f29 f16-mb"
                    ></h3>
                    <p
                        v-if="item.article"
                        v-html="wrap(item.article)"
                        class="article f14 f12-mb"
                    ></p>
                </article>
            </main>
            <ul class="w60 serif f16 f16-mb" id="doctrine-rules-pagination">
                <li class="prev" @click="prev">
                    <i class="icon-left-left f12 f12-mb"></i>戻る
                </li>
                <li class="title hide-mb">
                    {{ doctrineRules[activeRuleId].rule }}
                </li>
                <li class="next" @click="next">
                    次へ<i class="icon-right-right f12 f12-mb"></i>
                </li>
            </ul>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getDoctrineData from '@/data/getDoctrineData'
import { ref } from '@vue/reactivity'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'

export default {
    name: 'Doctrine',
    components: { LiteYouTubeEmbed },
    setup() {
        const { doctrineIntro, doctrineArticles, doctrineRules } =
            getDoctrineData()

        const activeRuleId = ref(0)
        function changeArticle(index) {
            activeRuleId.value = index
            scrollToElement('#doctrine-rules-article')
        }
        function prev() {
            if (activeRuleId.value === 0)
                activeRuleId.value = doctrineRules.length - 1
            else activeRuleId.value -= 1
            scrollToElement('#doctrine-rules-article')
        }
        function next() {
            if (activeRuleId.value === doctrineRules.length - 1)
                activeRuleId.value = 0
            else activeRuleId.value += 1
            scrollToElement('#doctrine-rules-article')
        }

        return {
            addBackgroundImage,
            wrap,
            doctrineIntro,
            doctrineArticles,
            doctrineRules,
            activeRuleId,
            scrollToElement,
            prev,
            next,
            changeArticle,
        }
    },
}
</script>
<style lang="scss" scoped>
#doctrine-main {
    padding-bottom: 50px;
    header {
        display: flex;
        align-items: center;
        h2 {
            @include h1Style();
            flex: 0 0 300px;
            margin-right: 50px;
            height: max-content;

            &:before {
                content: '';
                position: absolute;
                display: block;
                left: calc(50% - 150px);
                top: -110px;
                width: 300px;
                height: 300px;
                border-radius: 150px;
                background-color: rgba($color: $majorGold, $alpha: 0.2);
            }
        }
        p {
            line-height: 2em;
        }
    }
    .youtube-description {
        text-align: center;
    }
    @media screen and (min-width: 768px) {
        header {
            padding: 100px 10% 80px;
            box-sizing: border-box;
        }
        .youtube-container {
            padding: 50px;
        }
        .youtube-group {
            display: flex;
            justify-content: space-around;
            padding-top: 30px;

            .youtube-wrap {
                width: 45%;
                height: auto;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    @media screen and(max-width:767px) {
        header,
        .youtube-container,
        .youtube-wrap {
            padding-top: 30px;
        }
        img {
            padding-top: 30px;
            width: 100%;
            max-width: 612px;
            height: auto;
        }
    }
}
#doctrine-rules {
    border-top: none;
    padding-bottom: 50px;
    #doctrine-rules-selector {
        border-bottom: 1px solid $grayE;
        box-sizing: border-box;
        li {
            display: flex;
            i {
                display: inline-block;
                color: $grayB;
                margin-right: 0.5em;
                transform: scale(0.8);
            }
            span {
                display: inline-block;
                transition: 0.3s;
                cursor: pointer;

                &:hover {
                    color: $gray6;
                }
            }
        }
    }
    article {
        h3 {
            text-align: left;
        }
    }
    #doctrine-rules-pagination {
        padding: 50px 0;
        display: flex;
        justify-content: space-around;
        user-select: none;
        li.title {
            border-bottom: 1px solid $grayB;
        }
        li.prev,
        li.next {
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                color: $gray6;
            }
        }
        i {
            display: inline-block;
            transform: scale(0.6);
        }
    }
    @media screen and (min-width: 768px) {
        #doctrine-rules-selector {
            margin: 0 auto;
            padding: 80px 0 60px;
            display: grid;
            width: fit-content;
            max-width: 90%;
            grid-template: auto / repeat(3, minmax(200px, max-content));
            grid-gap: auto 0;
            li {
                margin: 10px 30px;
            }
        }
        article {
            padding: 50px 0;
        }
        article:first-child h3 {
            position: relative;
            box-sizing: border-box;
            padding-left: 48px;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 12px;
                display: block;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 5px solid $majorGold;
            }
        }
    }
    @media screen and(max-width:767px) {
        #doctrine-rules-selector {
            padding: 30px 0;
            li {
                margin: 15px 0;
                i {
                    position: relative;
                    top: -2px;
                }
            }
        }
        article:first-child h3 {
            position: relative;
            box-sizing: border-box;
            padding-left: 28px;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 8px;
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                border: 3px solid $majorGold;
            }
        }
    }
}
</style>
