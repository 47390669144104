export default function () {
    const doctrineIntro =
        '「親理」とは、大自然界の全てを営んで下さっている『大親神大生命』のお力・お働きであり、『大親神大生命』の御心・御意志・御意図です。『大親神大生命』御自親が私たちに、素晴らしく生きていく力・生き方・考え方を授けて下さる事を「貴き親理のお授け」と申し上げます。 \n \
        \n \
        世界各地で絶え間なく起きる対立・争い、異常気象、コロナ不況、環境破壊、生物絶滅･･･地球は今たくさんの問題を抱え、存続の危機に直面しています。『大親神大生命』は、地球人が親理に沿った生き方に変わり、お互いがプラスし合う素晴らしい惑星に地球がなれるように、お授けをして下さっておられます。'
    const doctrineArticles = [
        {
            article:
                '「親理」とは、大自然を構成している根本的な力と働きのことです。したがって「親理」とは、大自然界の全てを営んで下さっている『大親神大生命』のお力・お働きであり、『大親神大生命』の御心・御意志・御意図だということが分かります。\n \
                「親理」は、いつでも、どこでも、何にでも、誰にでも、時空間を超えて働いている大自然の絶対的な掟です。そして誰が気付こうと気付くまいと、好もうと好まざるとにかかわらず、永遠・普遍に働いている『大親神大生命』の絶対的なお力・お働きなのです。\n \
                \n \
                「貴き親理のお授け」は、大自然を創造し、全てを行って下さっている『大親神大生命』御自親でなければ絶対に出来ません。『大親神大生命』の貴き御心・御意志・御意図は『大親神大生命』より授けて頂かなければ、人間には絶対に分からないことなのです。\n \
                『大親神大生命』御自親が今、特別に地球に人間の言葉をもってお授け下さっておられます。地球がたくさんの問題を抱えて滅亡の危機に直面しているからこそ、生命の大親である『大親神大生命』は、地球人に「大自然の事実の教え」をお授けして下さっておられるのです。\n \
                \n \
                『大親神大生命』は大自然の事実を人間が分かるように、そして地球人が親理に沿った生き方に変わり、お互いがプラスし合う素晴らしい惑星に地球がなるように、お授けをして下さっておられます。',
        },
        {
            title: '貴き「親理のお授け」を頂くということ',
            article:
                '『大親神大生命』より貴き親理を授けて頂くという事は、親理のお力・お働きを頂くという事です。親理のお力・お働きを頂くという事は、親理に沿った生き方、感じ方、考え方が出来るようになる、という事です。\n \
                『大親神大生命』の貴き親理のお授けを、「なるほど」「そうなのか」と、単なる知識として知って満足しているだけは価値がありません。これは、私たち界員も陥りやすい間違いです。\n \
                『大親神大生命』が親理のお授けを行って下さる目的は、大自然界に通用する「理の生き方」が出来るように、私たちを育成して下さるためなのです。',
        },
        {
            title: '今の時代に必要な新しい生き方',
            article:
                '皆さんの中には「果たして今の生き方を続けていて良いのだろうか」「このままでは地球の未来は無いのではないか」と、薄々感じていらっしゃる方も多いのではないでしょうか？\n \
                世界各地で絶え間なく起きる土地や資源・金の奪い合い、人種・民族・国家・宗教宗派の違いによる対立、世界中で年々増加し激化する異常気象、相次ぐ巨大地震や火山噴火。経済も、コロナ不況、深刻化する地球環境問題、貧富の格差の拡大を前に破綻寸前です。\n \
                地球を存続させるためには、今までにない新しい生き方が必要です。『大親神大生命』の御心を悟り、全てを生命の家族として、お互いに想い合い・助け合う、新しい「理の生き方」です。そこで今、『大親神大生命』が地球に「理の生き方」を授けて下さる為に、貴き親理のお授けを行って下さっておられるのです。',
        },
    ]
    const doctrineRules = [
        {
            rule: '貴き親理のお授け',
            hasLink: false,
            mainArticle:
                '「親理」とは、大自然を構成している根本的な力と働きのことです。したがって「親理」とは、大自然界の全てを営んで下さっている『大親神大生命』のお力・お働きであり、『大親神大生命』の御心・御意志・御意図だということが分かります。\n \
                「親理」は、いつでも、どこでも、何にでも、誰にでも、時空間を超えて働いている大自然の絶対的な掟です。そして誰が気付こうと気付くまいと、好もうと好まざるとにかかわらず、永遠・普遍に働いている『大親神大生命』の絶対的なお力・お働きなのです。\n \
                \n \
                「貴き親理のお授け」は、大自然を創造し、全てを行って下さっている『大親神大生命』御自親でなければ絶対に出来ません。『大親神大生命』の貴き御心・御意志・御意図は『大親神大生命』より授けて頂かなければ、人間には絶対に分からないことなのです。\n \
                『大親神大生命』御自親が今、特別に地球に人間の言葉をもってお授け下さっておられます。地球がたくさんの問題を抱えて滅亡の危機に直面しているからこそ、生命の大親である『大親神大生命』は、地球人に「大自然の事実の教え」をお授けして下さっておられるのです。\n \
                \n \
                『大親神大生命』は大自然の事実を人間が分かるように、そして地球人が親理に沿った生き方に変わり、お互いがプラスし合う素晴らしい惑星に地球がなるように、お授けをして下さっておられます。',
            articles: [
                {
                    title: '貴き「親理のお授け」を頂くということ',
                    article:
                        '『大親神大生命』より貴き親理を授けて頂くという事は、親理のお力・お働きを頂くという事です。親理のお力・お働きを頂くという事は、親理に沿った生き方、感じ方、考え方が出来るようになる、という事です。\n \
                        『大親神大生命』の貴き親理のお授けを、「なるほど」「そうなのか」と、単なる知識として知って満足しているだけは価値がありません。これは、私たち界員も陥りやすい間違いです。\n \
                        『大親神大生命』が親理のお授けを行って下さる目的は、大自然界に通用する「理の生き方」が出来るように、私たちを育成して下さるためなのです。',
                },
                {
                    title: '今の時代に必要な新しい生き方',
                    article:
                        '皆さんの中には「果たして今の生き方を続けていて良いのだろうか」「このままでは地球の未来は無いのではないか」と、薄々感じていらっしゃる方も多いのではないでしょうか？\n \
                        世界各地で絶え間なく起きる土地や資源・金の奪い合い、人種・民族・国家・宗教宗派の違いによる対立、世界中で年々増加し激化する異常気象、相次ぐ巨大地震や火山噴火。経済も、コロナ不況、深刻化する地球環境問題、貧富の格差の拡大を前に破綻寸前です。\n \
                        地球を存続させるためには、今までにない新しい生き方が必要です。『大親神大生命』の御心を悟り、全てを生命の家族として、お互いに想い合い・助け合う、新しい「理の生き方」です。そこで今、『大親神大生命』が地球に「理の生き方」を授けて下さる為に、貴き親理のお授けを行って下さっておられるのです。',
                },
            ],
        },
        {
            rule: '大自然の大いなる意志と「創設設定の原理」',
            hasLink: false,
            mainArticle:
                '大自然には、大宇宙の大いなる現象・営みがあり、全生物の生命現象があります。これほど大いなる営みは、単なる偶然や無秩序で行われているのではありません。大自然の営みは全て、「大自然の大いなる生命」＝『大親神大生命』の大いなる御意志・御心と、そのお働きによりなされています。\n \
                大自然は、“自然とそうなっているもの”でも、“最初からそうなっているもの”でも、“あって当たり前のもの”でも、ありません。『大親神大生命』がそのような御意志をもって、意図的に、そのようにお創り下さったのです。',
            articles: [
                {
                    title: '創設設定の原理',
                    article:
                        '「創設設定の原理」という大自然の摂理・掟があります。「大自然のいかなる事柄も、その様になっているのは、その様にする意志の力と働きがあるからだ」という摂理・掟です。つまり「そのようにしなければ、そうなっていない」という、大自然の簡単・明確な原理です\n \
                        \n \
                        例えば人間社会で、「誰も何もしないで最初からそうなっている」ということはあるでしょうか？テレビが在るのは、テレビというものを作ろう、という人間の意志が働いているからです。人間社会の全ての事柄は、人間の意志の力と働きによってなされています。決して無意志や偶然でなされている事ではありません。\n \
                        \n \
                        星がある、太陽がある、地球がある、海があり山がある、そして全生物が生命をお与え頂いて活かされ生きている――大自然の全ては、『大親神大生命』が、大いなる御意志をもってお創り下さり、営んで下さっているのです。\n \
                        しかし人間は、大自然の大いなる営みを、当然の事、当たり前の事としてしか見ておりませんでした。一匹の蟻が巨大な高層ビルを見た時に、“すごく大きなビルを作ったもんだ！”と感心するでしょうか？人間ならば感心するでしょうが、蟻は“大きな山みたいなものが、あるべくしてある”としか感じないでしょう。同様に、人間は大自然の大いなる営みを見ても、『大親神大生命』の御意志・御意図には気付きもしなければ、考えもしなかったのです。',
                },
                {
                    title: '親の心、子知らず',
                    article:
                        '今まで誰も、『大親神大生命』の大いなる御意志・御心をわかろうとしてきませんでした。地球人類は「親の心、子知らず」の状態なのです。\n \
                        赤ちゃんにとって、親が衣食住すべてをしてくれるのは当たり前で、親の気持ちを考えたり、感謝したりはしません。しかし、親は当たり前に子育てをしているのではありません。子供を育てるということは、愛情があり、努力をしているのです。\n \
                        \n \
                        地球上に全生物がこうして活かされ生きているのには、その様にして下さっている大自然の大きな意志のお力・お働きがあります。『大親神大生命』が大きな御心と御愛によって、全ての全てをして下さっているのです。',
                },
                {
                    title: '大自然の大いなる御心を感じ、感謝することが大切です',
                    article:
                        '例えば、親が我が子にカレーライスを作って食べさせるのは、そこに「子供にこういうものを食べさせてあげたい」という親心・子供に対する愛があるからです。そのような気持ちが働いているからです。\n \
                        『大親神大生命』は私たち生命の御子に、大自然に生きる喜び・素晴らしさを味わわせて下さる為に、大親心と生命愛により、大自然を創造され私たち全てを生かして下さっています。\n \
                        \n \
                        現在まで地球人類は、大自然を理解するのに物理的側面からしか捉えていませんでした。「宇宙がどうなっているのか」「物理法則がどうなっているのか」――そのような物理的な見方しかしておりませんでした。今まで人間が行ってきた事は、親の心を全く無視して、目の前にあるカレーライスの作り方や原材料を素粒子レベルまで調べているようなものです。しかし、最も大切な事は、そのようにしてくれた親の心、親の大きな愛を感じ、感謝して生きる事ではないでしょうか。\n \
                        \n \
                        大自然を物理的な側面から見るだけでは、本当に理解した事にはなりません。大自然の事柄・現象の事実を通して、大自然の大いなる意志・御心を感じる事が、“大自然を理解する”という事です。そして、生命の大親の大いなる愛を感じ、親感謝させて頂く事が、私たち生命の御子にとって最も大切な事なのです。',
                },
            ],
        },
        {
            rule: '神の本質',
            hasLink: false,
            mainArticle:
                '古今東西、人間はそれぞれの宗教宗派で様々な神仏を作り、拝んできました。あるいは”神”という存在や概念について、様々な議論を重ねてきました。では本当の“神”とは、どのような御存在なのでしょうか？',
            articles: [
                {
                    title: '神とは、全てに絶対に必要不可欠なる御存在です。',
                    article:
                        '神とは、全てのものにとって絶対的に必要不可欠で、なくてはならない御存在です。いつでも・どこでも、誰にとっても、何にとっても、無くてはならない存在である事が「神」の本質です。\n \
                        \n \
                        私たちがこの世に生存するのに必要不可欠な存在とは、全てに生命をお与え下さっている「大自然の大いなる生命のお力・お働き」です。大自然の大いなる生命のお力・お働きが無ければ、全生物は生きてはいけません。生物だけではなく、地球も宇宙も、大自然の一切の存在も現象もあり得ません。私たち全生物、全存在は、大自然の大生命のお力・お働きによって創造され、この世に存在しているのです。\n \
                        \n \
                        一般宗教の神仏の概念は、絶対的に必要不可欠な存在ではありません。人間以外の生物にとって、人間が作った神や仏が有ろうが無かろうが、一切関係がありません。「生きる」という最も大切な事に直接関わりがないものです。地球人類の宗教宗派・神仏の全てが無くても関係なく、大自然の営みはなされ、全生物は活かされ生きております。また、宗教宗派の神仏を信じる・信じないは、各人の自由選択の余地があります。自由選択出来るものは「絶対」ではありません。\n \
                        \n \
                        神の本質とは、気付こうと気付くまいと、信じようと信じまいと、好むと好まざるとに拘らず、絶対的に必要不可欠なる御存在なのです。',
                },
                {
                    title: '神とは、永遠・普遍共通なる御存在です。',
                    article:
                        '神とは、全てにとって永遠・普遍共通なる御存在です。いつ、どこで誰にでも、何にでも通用する必要不可欠な御存在です。時代、民族を越え、宗教宗派を超越した御存在です。\n \
                        一宗一派にだけ通用し、他宗教には通用しない神名や神の概念、偶像、形式は、全て人間が創作したものであり、神の本質ではありません。\n \
                        \n \
                        例えば、「太陽」そのものは、地球の全生物にとって普遍的共通の存在です。しかし、人間が太陽を人格化した場合、「天照大御神（女性）」「大日如来（中性）」「ヘリオス（男性）」というように、神の概念、呼名、性別などが異なります。人格神化された神は、人為的に観念の産物として創られたものだからです。\n \
                        「エホバの神（ユダヤ教）」「天なる父（キリスト教）」「アッラーの神（イスラム教）」などの神の概念も、永遠普遍なる存在ではありません。人間が作り出したものであり、また、それを信じる人々にしか通用しないからです。\n \
                        \n \
                        この宇宙ではビッグバン以来の138億年、数多の星が誕生し銀河を形成しています。地球上では46億年も生命現象が行われています。大自然を現わして下さっているのは、大自然の大生命のお力・お働きです。\n \
                        全てに生命をお与え下さり、大自然の全てを行って下さっている「大自然の大生命のお力・お働き」こそ、永遠普遍なる神の本質なのです。',
                },
                {
                    title: '神とは、大自然、大宇宙の創造主です。',
                    article:
                        '神とは、万物全ての創造主です。万物は、大自然の大いなるお力・お働きによって創造されたものです。\n \
                        大自然の大いなる生命のお力・お働きによって、全生物が生命を与えられ存在しています。生物だけではありません。物質も、E=MC \
                        <sup class="smaller">2</sup> \
                        の式で表わされるように、エネルギーによって出来ています。\n \
                        \n \
                        全生物、全物質、あるいは時間や空間まで、大自然の全ては、大自然の大いなる生命のお力・お働き＝『大親神大生命』がお創り下さったものです。『大親神大生命』こそ、御自親の生命エネルギーをもって大宇宙を創造され、大自然の真理法則をもってあらゆる生命の現象と営みを行って下さっている「神の本質」そのものなのです。',
                },
                {
                    title: '神とは、生命ある全存在の大親です。',
                    article:
                        '神の本質とは、大自然・大宇宙の根元的創造主です。全存在の創造主という事は、全存在の「大親」であると言えます。\n \
                        生命ある全ての存在は、大親である大生命の生命の一部ずつを頂いている「生命の御子」です。生命には動物・植物の差別も、人種差別も、宗教差別もありません。皆、同一の大親から出発した生命の兄弟姉妹なのです。\n \
                        \n \
                        大自然の大いなる生命『大親神大生命』は、御自親の大生命の一部を全てにお与え下さり、全てをより良く活かそうと、大自然のあらゆる営みを続けて下さっておられます。\n \
                        \n \
                        神とは、全ての生命をこの世に生かして下さっている、大親の絶対愛そのものです。生きとし活ける全てのものをより良く活かそうとする大親の心が、神の本質なのです。',
                },
                {
                    title: '神の本質を悟った新しい生き方',
                    article:
                        '真の「神の本質」は、全生物にとって絶対に必要不可欠であり、しかも永遠・普遍共通なる御存在であるはずです。全生物に生命を与え生かして下さっている大自然の大親の大生命＝『大親神大生命』こそ、全生物の生存にとって必要不可欠であり、神の本質なのです。\n \
                        \n \
                        もし人類が歴史の初めから『大親神大生命』という大自然の大いなる生命の営みを「神の本質」として悟っていれば、宗教宗派における対立・争いは無かったでしょう。\n \
                        『大親神大生命』という神の本質を悟る事が出来れば、人類は地球だけではなく、銀河や大宇宙における全ての生命現象に対してもみな『大親神大生命』の生命の家族であるという家族愛と一体感を持たせて頂く事が出来ます。\n \
                        私たち人類は『大親神大生命』という永遠普遍なる神の本質を、今こそ認識すべき時なのです。',
                },
            ],
        },
        {
            rule: '自明の理',
            hasLink: false,
            mainArticle:
                '「自明の理」という言葉があります。「自」は自分の「自」、「明」は明らかにするという意味です。「自明の理」とは、大自然は事実をもって事実を証明しているということです。「事実をもってのみ」というのがポイントです。\n \
                例えば“リンゴである”ということを証明するには、実際のリンゴを持ってきます。ミカンを持ってきてもバナナを持ってきても、リンゴの証明にはなりません。リンゴという事実だけが、リンゴを証明いたします。大事なことは、証明には「事実」が必要だということです。',
            articles: [
                {
                    title: '宗教は「自明の理」の証明が出来ない',
                    article:
                        '宗教は単なる人間の言葉であって「自明の理」の証明は出来ません。事実ではないから証明できないのです。「天なる父の神」がいるとイエスという男性が言っただけです。「アッラーの神」というのはムハンマドという人物が言ったのです。彼がいなければ「アッラーの神」は地球上に存在しませんでした。伊勢神宮に祭られている「天照大御神」も、最初に誰かが太陽の神が天照大御神だと言っただけです。しかし人間は、その言葉が事実であるかどうか調べもせずに、頭から信じてしまっているのです。\n \
                        天なる父もアッラーの神も天照大御神も、人間が信じている神様は、人間が作り出したものです。例えば、東京には東郷平八郎元帥を祀った東郷神社があります。日露戦争の日本海開戦で勝利したからと、東郷平八郎元帥を神様にして東郷神社を作ったのです。人が神様になっているのはおかしな話だなと、違和感を持っている方もいらっしゃるのではないでしょうか？しかし宗教とはそういうものなのです。人間が自分の好き勝手に神様を作り、事実ではないことを事実であると信じ込んでいる、それが宗教なのです。',
                },
                {
                    title: '『大親神大生命』は「自明の理」の御存在です',
                    article:
                        '「神」の本質とは、絶対必要不可欠で全生物にとって最も大事な存在ではないでしょうか。全生物にとって最も大事なものは「大自然の生命のお力・お働き」です。全生物が、大自然の大いなる生命のお力・お働きによって、生命を頂いて活かされ生きています。そしてその大自然の生命のお力・お働きが無くなったら、誰も生きてはいけないのです。誰も自分の生命を自分で創ることは出来ません。\n \
                        「大自然の大いなる生命のお力・お働き」というのは、全生物にとって無くてはならない、絶対に必要不可欠な存在です。大自然界では、「大自然の大いなる生命のお力・お働き」を『大親神大生命』とお呼びします。\n \
                        大自然の事実を一つずつ調べてゆくと、全て『大親神大生命』の御心がそのようにして下さっておられるのだと分かります。例えば太陽があるのは、『大親神大生命』が「この太陽系には、このくらいの大きさの太陽が必要だな」と現わして下さったからです。小さすぎれば太陽系が纏まりませんし、大きすぎれば全てが太陽に引き寄せられて燃え尽きてしまいます。『大親神大生命』が、地球が周りを回るのに丁度良い質量・大きさの太陽を現わして下さっておられるのです。地球と太陽の距離も、地球に届く熱量が生物の生存にとって必要な範囲になるようにして下さっています。こうして地球上では40億年間も生命現象が行われているのです。これは人間の力と働きではございません。『大親神大生命』の御存在というのは、「大自然の生命のお力お働き」という事実をもって事実を証明している「自明の理」なのです。',
                },
                {
                    title: '本当の事＝真理に沿って生きる',
                    article:
                        '『大親神大生命』という絶対的な「生命の親」がいて下さり、その「生命の親」がいて下さるからこそ、全生物が生まれて活かされ生きています。これが事実です。人類はこの事実に目覚めて、宗教宗派で争い対立し生命を奪い合う生き方を変える必要がございます。\n \
                        『大親神大生命』によって生命を頂いて活かされ生きているのですから、『大親神大生命』の大自然の親理に沿って生きてゆくのが本来の姿なのです。\n \
                        例えば大自然は「循環の理法」によって成り立っております。これが真理であることは、地球の自転・公転、地球上の水の循環、春夏秋冬、人間の体の新陳代謝・血液・呼吸など、大自然の事実が証明しています。自分たちが蒔いた種がやがて「循環の理法」で生えてくる、という事実が常識になれば、人類は戦争をして生命を奪い合ったり、お互いにマイナスを与えるような生き方はしなくなるでしょう。全生物を絶滅させるような生き方をしなくなるはずです。\n \
                        私たちは全て『大親神大生命』から生命をお与え頂いて活かされ生きております。これが本当の事、真理です。もしも、こういう考え方を人類全員が持ったなら、地球はもっとより良い、住みよい星になります。全てが『大親神大生命』の同じ生命を頂いた兄弟・家族として、お互いに支え合い・助け合って生きる、素晴らしい地球になるのです。',
                },
            ],
        },
        {
            rule: '大自然の真理法則',
            hasLink: false,
            mainArticle:
                '私たち人間をはじめ、生命ある全生物は、大自然の大いなるお力・お働きによって、この世に活かされ生きております。生物だけではなく、この世のあらゆる存在は、星も、太陽も地球も、分子も原子も素粒子も、全てが大自然のお力・お働きによって成立し、存在し消滅しております。\n \
                \n \
                この大自然の大いなる営みには、一定の秩序と掟があります。この秩序と掟の事を「大自然の真理法則」と言います。\n \
                「大自然の摂理」「天然の理」「自然法則」「真理」「神の掟」「道理」など、色々な呼び方をしても、中身は同じ事です。',
            articles: [
                {
                    title: '“当然の事”こそ、大自然の事実そのもの',
                    article:
                        '中には“世の中はそう出来ているのだ”、“世の常だ”、“世の定めだ”、“当たり前の事だ”と言う人もいるでしょう。しかし実は、この当然な事こそ、大自然の絶対的な秩序と掟であり、大自然の真理法則の働きによる事実そのものなのです。\n \
                        \n \
                        例えば、「十人十色」という諺は、“世の中の全ての物事は、みなそれぞれが違っている”という当たり前の事を表現したものです。\n \
                        “世の中の全ての物事は、みなそれぞれが違っている”、これを「種類分化の理法」と言います。大自然の真理法則の一つです。この世の中に色々なものがあるという事は、大自然の真理法則である「種類分化の理法」の力が働いている証拠なのです。',
                },
                {
                    title: '大自然の全てに働く絶対的な掟',
                    article:
                        '「大自然の真理法則」とは、大自然の全てを成り立たせている、最も根本の原理です。この世は、大自然の真理法則によって創られておりますので、大自然の真理法則が通用しないものはありません。\n \
                        \n \
                        「大自然の真理法則」は、いつでも、どこでも、誰にでも、何にでも、絶対的に働いている大自然そのものの力です。私たち人間が、好むと好まざるとに拘らず、気付こうと気付くまいとにかかわらず、運命や環境・心や肉体など、大自然のあらゆるものに働いている永遠・普遍の掟なのです。\n \
                        \n \
                        「大自然の真理法則」は、人間が決めた掟や法律ではありません。大自然を行って下さっている『大親神大生命』が、大自然の全ての営みを行う為に、永遠・普遍に定めた絶対的な掟であり、秩序です。\n \
                        いつ、どこに、どのように生まれるかという全生物の誕生から、その運命・環境の全てに至るまで、あらゆる現象が「大自然の真理法則」という『大親神大生命』のお力とお働きによって現わされているのです。',
                },
                {
                    title: '大自然の真理法則を悟る事は、全ての人に必要です',
                    article:
                        '大自然の真理法則を学び、悟る事は、老若男女・人種・宗教宗派の差違を超えて、誰にとっても絶対に必要な事です。\n \
                        \n \
                        大自然の真理法則を学び悟る事は、自分の心や人格、霊魂や生命の“食物・栄養”になります。真理法則の悟りという心の栄養が不足してきますと、肉体的・物質的には生きてゆく事が出来ても、生きてゆく人生の質や、生きがいの中味の質が低下して栄養失調となり、不足不満、不幸・不健康な運命・環境となります。\n \
                        \n \
                        大自然の真理法則を悟る事により、誰でも自分の人生をより充実させ、今日一日の生存の喜び・味わい・感謝を実感として持つことが出来るように絶対になります。\n \
                        \n \
                        なぜなら、「大自然の真理法則」こそが、私たち人間の人生・運命・環境の全てを含め、大自然のあらゆる営みを実際に行って下さっている絶対的な力と働きであり、『大親神大生命』は生きとし活けるもの全てに、生命を与え、より良く生かそうとして下さっているからです。',
                },
            ],
        },
        {
            rule: '十元の理法',
            hasLink: false,
            mainArticle:
                '「十元の理法」とは、大自然を構成している基本的・根本的な原理・原則です。\n \
                \n \
                大自然の全ての営みをして下さっている『大親神大生命』のお力・お働き（大自然の真理法則）を、十の面より認識したものです。「十元の理法」のどれ一つが無くても、大自然は成り立ちません。\n \
                \n \
                <ul class="disc-list-ul"> \
                    <li class="disc-list-li">能動変化の理法	･･･全てが能動的に変化している事。大いなる方向へと向上している事。</li> \
                    <li class="disc-list-li">平均・調和・安定の理法･･･全てはより大きな安定調和へ向かって変化している事。</li> \
                    <li class="disc-list-li">出発発展の理法	･･･全ては常に新しい形状に向かって発展している事。</li> \
                    <li class="disc-list-li">種類分化の理法	･･･全ては固有性をもった種類に分化されている事。</li> \
                    <li class="disc-list-li">循環の理法	･･･全ては循環している事。繰り返しながら新化発展している事。</li> \
                    <li class="disc-list-li">強度継続の理法	･･･全てはその様であろうとする事。（慣性の法則はその一例）</li> \
                    <li class="disc-list-li">作用総合の理法	･･･全ては総合的に作用し影響し合っている事。</li> \
                    <li class="disc-list-li">原因・結果の理法･･･全ては原因・種であり、全ては結果・収穫である事。</li> \
                    <li class="disc-list-li">機会・場の理法	･･･あらゆる事が全て向上発展の機会であり、場であり、チャンスである事。</li> \
                    <li class="disc-list-li">終止限界の理法	･･･全てにある一定の限界があり、終わりがある事。</li> \
                </ul> \
                \n \
                「十元の理法」を学修し、理法に適った生き方をする事により、自分の運命・環境、事情・身情、人間関係などあらゆる人生問題を解決し、人生をますます明るい幸せなものへと発展させる事ができます。',
            articles: [],
        },
        {
            rule: '循環・還元の理法',
            hasLink: true,
            mainArticle:
                '上の写真は、大自然の真理法則の力と働きによって、銀河の一つが回転している姿です。約1000億から2000億の恒星が集まり回転運動をしています。このように、全てを回転させている力と働きを「循環・還元の理法」と言います。大自然の真理法則の一つです。',
            articles: [
                {
                    title: '「循環・還元の理法」は全てに働いています',
                    article:
                        '大自然の全ての現象・存在は全て「循環・還元の理法」によって成立しています。\n \
                        \n \
                        <ul class="disc-list-ul"> \
                            <li class="disc-list-li">体内の心臓の鼓動、血液の循環、新陳代謝、睡眠と覚醒、生まれる事と死ぬ事など、全て繰り返しの循環です。</li> \
                            <li class="disc-list-li">地球の公転・自転、春夏秋冬の移り変わり、海流・河川の流れ、地殻のマグマなども、全て繰り返しの循環です。</li> \
                            <li class="disc-list-li">原子核を中心に回転している電子、原子核内の陽子や中性子の回転運動なども循環です。</li> \
                            <li class="disc-list-li">麦の種を蒔いて麦が実り、またその麦が種となって実を実らせる現象も循環です。</li> \
                            </ul> \
                        \n \
                        このように、「循環・還元の理法」は、大自然の全ての存在・現象に、一様に当てはまる真理法則です。宇宙を超え、時代を超えて、大自然の全ての存在や現象に働いています。したがって、「循環・還元の理法」は永遠であり、普遍的であると言えます。\n \
                        \n \
                        大自然の真理法則は、いつでも、どこでも、何にでも、誰にでも、時空間を超えて働いている、大自然の絶対的な掟です。しかも、誰が気付こうと気付くまいと、また好むと好まざるとにかかわらず、永遠・普遍に働いている大自然そのものの絶対的な力なのです。',
                },
                {
                    title: '「循環・還元の理法」は運命・環境にも当てはまります',
                    article:
                        '大自然の真理法則は永遠・普遍ですから、「循環・還元の理法」は人間の運命や環境、心と肉体などにも当てはまります。\n \
                        \n \
                        人生に対する肯定的プラスの考え、感謝の気持ちや他の役に立つ行いは、それがプラスの種となって、自分の人生にプラスの運命・環境が生えてくる結果となります。\n \
                        逆に、人生に対する否定的マイナスの考え、不足不満の気持ちや行いは、それがマイナスの種となって、自分の人生にマイナスの運命・環境が生えてくる結果となります。“悪循環”です。\n \
                        \n \
                        「循環・還元の理法」を悟った人は、悪い人間関係をプラスの良いものへと切り換えてゆく事ができます。「循環・還元の理法」が分からない人は、悪い人間関係をますます悪くしてしまいます。知らない間に、理法を悪循環の方へ応用してしまうのです。',
                },
                {
                    title: '「循環・還元の理法」をプラスに活用しましょう！',
                    article:
                        '「循環・還元の理法」は、この世をより良く構成している真理法則ですから、元来、より良いものであり、プラスのものです。「種を蒔いて、より大きな収穫を得る」という循環があるからこそ、農業も、人間の社会生活も、子孫繁栄という生物の生命現象も成り立つのです。したがって、「循環・還元の理法」は、よりプラスのものを創造し発展していく事に応用する事が、本来のあるべき姿なのです。\n \
                        \n \
                        「循環・還元の理法」は「蒔いた行いの種によってその結果が循環で戻ってきますよ」ということです。では、今まで人間が蒔いてきた「行いの種」によって、地球上で今どんなことが起きているでしょうか？地球資源の枯渇、天変地異、核戦争の危険性などにより、人間は存続の危機を迎えています。しかしそれは、人間が我欲を満たす為に他の生命を粗末にし、色々な種を絶滅させるというマイナスの種を蒔いてきた結果なのです。\n \
                        \n \
                        私たちは今こそ、大自然の摂理に沿った生き方、『大親神大生命』の御心に沿ったプラスの生き方に変わる必要がございます。「循環・還元の理法」をプラスに活用して、自分の運命・環境を、そして地球の未来を、ますます明るい幸せなものへと発展させていきましょう！',
                },
            ],
        },
        {
            rule: '種類分化の理法',
            hasLink: false,
            mainArticle:
                '“世の中の全ての物事は、みなそれぞれが違っている”、“個は他の個たり得ない”、これを「種類分化の理法」と言います。大自然の真理法則の一つです。\n \
                \n \
                この世の全てのものは、皆それぞれに違っています。全く同じものは、この世に二つと存在していません。たとえ双子であっても、全く同じ顔ではありません。「十人十色」という諺は、“世の中の全ての物事は、みなそれぞれが違っている”という事を表現したものですが、これも「種類分化の理法」を基にした考え方です。',
            articles: [
                {
                    title: '「種類分化の理法」は全てに必要不可欠な真理法則です',
                    article:
                        'この世の中に色々なものがあるという事は、大自然の真理法則である「種類分化の理法」の力が働いている証拠です。この理法の力と働きにより、大自然の全ては、様々な異なったものとして種類分化されて存在しているのです。星、太陽、地球、様々な生物、色々な物質があって、この世の中は成り立っております。\n \
                        \n \
                        もし「種類分化の理法」の働きが無くなったならば、どうなってしまうでしょうか？\n \
                        \n \
                        この世の全てが一種類の同一体となります。様々な細胞や臓器の違いが無くなってしまいますから、私たちの体は存在できません。生物の多様性が無くなれば、このような大自然の姿や営みも成り立ちません。様々な分子・原子・素粒子の差が無くなりますので、全宇宙、物質世界そのものが全く有り得なくなってしまいます。\n \
                        \n \
                        このように「種類分化の理法」は、この世を成り立たせている最も根元的・基本的なこの世のルールであり、私たち人間を含め、大自然の全てにとって絶対的に必要不可欠な真理法則なのです。',
                },
                {
                    title: '全ては固有性がある唯一の大切な存在です',
                    article:
                        '「種類分化の理法」により、全ての個はそれぞれの固有性をもって存在しています。この広大なる大自然、大宇宙の中で、“自分”という個は他に絶対に二つと無い存在です。\n \
                        \n \
                        「自分はこの世で他の誰も持っていない特有性を持った存在である」「自分は『生命の大親』の貴い生命の一部を頂いた、生命の御子という貴い存在である」――自分の存在を尊いものとして自覚する事こそ、自分を高め、また他をプラスにする為に最も大切な事なのです。\n \
                        真の喜びや幸せとは、自分が自分自身を尊敬し、自分を肯定する事にあります。自分が自分を否定する事は、自分にとって一番辛く耐えがたい不幸です。',
                },
                {
                    title: 'それぞれの固有性を尊重し肯定する',
                    article:
                        'この世は「種類分化の理法」でそれぞれが固有性を持って成立しています。したがって、互いに個を認め合い、尊重し合う事は、理法に適ったことです。自分という個を尊重するからこそ、他の個の存在を認め尊敬するのです。逆もまた真なりで、他の個を尊重するからこそ、自分という個の存在をも尊敬するのです。\n \
                        \n \
                        「自分を肯定する」と言うと、人間は自分と異なる感じ方や考え方に対して、対立的に考えやすくなります。\n \
                        しかし、自分が気に入らないからと、感情だけで相手を非難し否定することは間違いです。自分という個だけを尊重して自己中心の考えや自分だけのエゴ（我）を通すことは間違いです。自分の国の利益だけを追求したり、人種差別や宗教宗派で互いに争い貶し合うことは、理法に逆らったことであり、間違いなのです。 ',
                },
                {
                    title: '多様な個が存在するには、永遠普遍共通の価値観が必要不可欠です',
                    article:
                        'あるいは、「自分を肯定する」と言うと、人間はそれぞれが自由に、自分の好きに生きる事が幸せだと勘違いしがちです。\n \
                        最近は「価値観の多様性」という言葉がもてはやされています。「価値観の多様性」とは、“それぞれが自分の好き勝手に生きる”という意味です。これは一見、対立せずに個々を尊重しているようですが、結局、バラバラでまとまりがつかないという事に他なりません。\n \
                        \n \
                        個々が我を通して全体としての秩序を失う事は、各自が交通ルールを守らずに暴走しているようなものです。自動車を運転する時に、「私は緑ではなく赤が好きだから、赤信号で進みたい」「一方通行？でも私はこっちに行きたいんだ」というように、全員が交通ルールを全く無視して自分の我を通していれば、必ず事故が起きます。そもそも交通が成り立ちません。\n \
                        \n \
                        大自然は、「種類分化の理法」により、それぞれの個の特色を肯定して存在させているのと同時に、全体としての秩序とまとまりをもっています。それは大自然の真理法則、つまり『大親神大生命』の御心という、永遠普遍の価値観があるからです。\n \
                        \n \
                        それぞれが自由に個人の気持ち・固有性を持つ事は素晴らしい事であり、「種類分化の理法」に適った事です。ただしそれは、全員が「大自然界の真理法則」というルールに沿った、共通の生き方が基本として出来る事が大前提です。これは、交通ルールを守った範囲でならば、自由に何処にでも運転して行って良いですよ、というのと同じ事です。\n \
                        \n \
                        『大親神大生命』の御心（大自然の真理法則）に沿って生きる事が出来れば、人類を含め全生物は『大親神大生命』の生命の家族としての一体感で全体がまとまる事ができるのです。',
                },
            ],
        },
        {
            rule: '「生きる」とは何か？ -生存の本質-',
            hasLink: true,
            articles: [
                {
                    title: '生存とは、生命活動の姿です。',
                    article:
                        '「生きる」という事は、生命が活動している姿です。生命の活動が無ければ生存という事は成り立ちません。生命が活動しているからこそ、生存行為という働きがあるのです。生命活動の力と働きを失った肉体は、「死体」や「遺体」と呼ばれて、生きている事と区別します。「死人」と言う場合も、生きている人との区別であり、生命活動が肉体から無くなった状態を言うのです。つまり、生きるという事は、生命が活動している姿を言うのです。「生命活動」が「生きる」という事の本質なのです。これを「生命活動の原理」と言います。\n \
                        生物は全てこの原理により生きております。例えば、植物の場合でも原理は同じです。2000年も生きていると言われている屋久島の杉の大木も、生命活動がある限り枝をのばし、葉を繁らせて、「生きる」という生存行為をしています。もし切り倒されて材木となったなら、もう植物としての生命活動はありません。つまり、木としては死んだのです。「生命活動の原理」から観れば、材木とは木の死体であると言えるでしょう。「生命活動の原理」は植物にも動物にも、動きある全ての物質・現象に当てはまるのです。',
                },
                {
                    title: '生命活動の三つの次元',
                    article:
                        '生命活動には三つの形態（次元）があります。\n \
                        \n \
                        <b class="bold"> \
                            <i class="underline">☆生命活動</i> \
                        </b>\n \
                        生命とは、自ら能動的に何かを行う力と働きをもった存在です。能動的存在ですから、常に何かをしております。生命が何かを行ってゆく事を、生命活動と言います。生命が活動している姿が、生きている事なのです。\n \
                        永遠・普遍の御存在である『大親神大生命』より頂いた「生命」は、永遠・普遍の存在です。生命とはエネルギーそのものであり、肉体のように死んだり無くなったりはいたしません。エネルギーは決して無くならない、という事を物理的に捉えたのが「エネルギー保存の法則」です。つまり、私たちは本質的には永遠・普遍の存在であり、決して死なないのです。生まれ変わりしながら、永遠普遍に生きているのです。\n \
                        \n \
                        <b class="bold"> \
                            <i class="underline">☆霊的生命活動、霊的生存</i> \
                        </b>\n \
                        生命が霊的観念を収穫し味わってゆく生命活動を、霊的生存と言います。つまり生命が霊的に生きていく事＝霊的な生命活動を行う事です。霊的な生命活動というのはちょうど、夢を見るような活動です。夢の世界では、生命が色々な霊的観念を味わっております。肉体的に生きて恐怖感を霊的観念として収穫した者は、恐怖の夢を見て、恐怖を味わう霊的生存をします。肉体的に生きて楽しさを霊的観念として収穫した者は、楽しい夢を見て、楽しさを味わう霊的生存をするのです。\n \
                        自分の生命が作り出した観念、意識を「霊的観念」と言います。例えば全く同じDNAをもった双子でも、それぞれに霊的観念が異なるので、人格・性格・思考・感情・気持ちが違うのです。生命は永遠・普遍共通なので、「個」や自他の差はありません。個性の違いは、霊的観念の違いなのです。霊的生命活動とは、生命が物質現象界において人格・性格・思考・感情・気持ちを表現し、肉体（物質体）の生存行為を行う事です。また、物質現象界で肉体をもった生存を体験・経験して、様々な事柄を味わい、霊的観念として収穫してゆく事も、霊的生命活動です。\n \
                        \n \
                        <b class="bold"> \
                            <i class="underline">☆肉体（物質体）的生命活動、肉体的生存</i> \
                        </b>\n \
                        生命が肉体（物質体）をもって生命活動を行う事を、肉体（物質体）的生存と言います。つまり、肉体を動かしてゆく事です。人間や動物の肉体、植物の体などを含めた全ての生物の体を「物質体」と言います。\n \
                        この物質現象界で、生命が物質的に生きる（生命活動を行う）場合には、何らかの形態の物質体が必要です。物質体とは、生命が物質現象界において活動する為の材料なのです。この事を分かりやすく例えますと、自動車レースに参加する為には自動車が必要です。自動車はレースを行う為の材料なのです。自動車が無ければ、たとえ運転する者がいても、自動車レースには参加出来ません。\n \
                        物質現象界での生命活動を行う場合にも、物質体なしには出来ません。自動車レースに例えれば、生命が運転者で、物質体が自動車という事になります。生命が存在しても、物質体がなくては物質的生命活動は出来ません。私たち人間は、『大親神大生命』がお貸し与え下さった「人間自動車」を運転し動かしながら、物質現象界での肉体的生命活動を行わせて頂いているのです。',
                },
                {
                    title: '霊的生存と肉体的生存',
                    article:
                        '肉体を動かす事が肉体的生存であり、色々な事柄を知ったり・感じたり・考えたりする心や精神の活動は、霊的生存をしているという事なのです。つまり私たちは、肉体的生存を行いながら、同時に霊的生存を行っているのです。全ての「生命」は、肉体の有る・無しに拘わらず、霊的生存という形で永遠・普遍に生きていると言えます。\n \
                        先ほどの自動車と運転手で例えれば、自動車に乗っていようといまいと、運転手が色々な事を感じたり考えたりしている事に変わりはありません。地球人類はこれまで肉体的生存だけを「生きている」事として捉えてきましたが、今生の肉体的生存の運転を終えて、運転者（生命）が人間自動車から降りても、霊的生存＝霊的に生きる事は、永久に続いてゆくのです。ですから、どのような霊的観念を収穫するか、という事が非常に大事なのです。\n \
                        物質現象界（この世）は、私たち生命の御子たちが霊的観念の収穫をする為に、『大親神大生命』がお創り下さった世界です。心は霊的観念の世界より物質現象界に開いた窓口です。この窓口を通して全ての霊的観念の収穫と霊的な成長発展が行われるのです。肉体的生存により物質現象界の事柄を実際に体験・経験して、この事をプラスの気持ちで通ってゆく事が、『大親神大生命』が私たちに望んでいて下さる霊的生存・肉体的生存の仕方です。ですから、いかなる運命・環境であろうとも、いかなる事情・身情があろうとも、プラスの気持ちをもって生きて行く事が大切なのです。肉体的一生を通して素晴らしいプラスの霊的生命活動を行った場合、そのような素晴らしいプラスの生命霊（霊魂）となり、いつでもどこでも素晴らしいプラスの霊的生命活動・生き方を行う事が出来るのです。',
                },
            ],
        },
        {
            rule: 'なぜ生きるのか　-人生の本当の目的-',
            hasLink: false,
            mainArticle: 'なぜ生きるのか　-人生の本当の目的-：內文',
            articles: [
                {
                    title: 'なぜ生きるのか　-人生の本当の目的-',
                    article: 'なぜ生きるのか　-人生の本当の目的-：內文1',
                },
                {
                    title: 'なぜ生きるのか　-人生の本当の目的- 2',
                    article: 'なぜ生きるのか　-人生の本当の目的-：內文2',
                },
                {
                    title: 'なぜ生きるのか　-人生の本当の目的- 3',
                    article: 'なぜ生きるのか　-人生の本当の目的-：內文3',
                },
            ],
        },
        {
            rule: '自分とは何か　-自己の本質-',
            hasLink: true,
            articles: [
                {
                    title: '自己の本質は、肉体ではなく「生命」です',
                    article:
                        '大自然の大いなる生命のお力・お働きである『大親神大生命』は、私たち全てに生命を与えて下さり、生かして下さっています。自己の本質は、『大親神大生命』より頂いている「生命」そのものです。肉体は自分自身ではありません。肉体とは、この世（親物質現象界）で一時的に『大親神大生命』よりお借りしている、仮の姿なのです。\n \
                        生物の生死とは、肉体を基準にしているのではなく、生命の活動を基準にしています。肉体があっても生命活動が無くなれば、亡くなったと言います。つまり、自己の本質は生命であり、肉体ではないのです。「あの人は亡くなった」と言った場合、その人の肉体を意味してはおりません。「あの人」とは、生命を意味しているのです。この事からも、全ての生物の本質は生命であるという事が分かるのです。',
                },
                {
                    title: '「生命」とは何かをする力と働きです',
                    article:
                        '生命とは、何かをする力と働きです。体を動かしたり、何かを考えたり感じたりする事も、生命があるから出来るのです。生命が無ければ、暑さも寒さも何も感じません。だからこそ、生命が無くなった＝死んだ時には、火葬場に肉体を持って行って火葬にするのです。もしも生命がある間に火で燃やしたら、殺人になってしまいます。何かを感じたり・思ったり・判断したり・考えたり・認識したりする事も、生命があるからこそ、このような活動をする事が出来るのです。\n \
                        例えるならば、生命とは電気のようなものです。コンピューターが動くのは、電気が通っているからです。コンピューターは、電気が止まってしまえば何も出来ません。コンピューターのあらゆる動きは、電気（エネルギー）の力と働きです。つまり「生きる」という事は、生命（エネルギー）の力働であり、生命が活動している姿なのです。',
                },
                {
                    title: '生命の永遠・普遍性',
                    article:
                        '永遠・普遍なる『大親神大生命』御自親の一部を頂いた「生命」は、永遠・普遍なる存在であり、自分の生命エネルギーは不滅です。生命は肉体のように死んだり無くなったりはいたしません。つまり、私たち＝「生命」は、時間・空間を越えて永遠・普遍に生命活動を行っていくのです。\n \
                        物理学の「エネルギー保存の法則」とは、エネルギーは決して無くならない事を意味しております。現代の人類は、エネルギーが不生・不滅（生まれも死にもしない）ものであり永遠・普遍に存在するものである事を、物理の科学的知識としては分かっているのです。\n \
                        『大親神大生命』より生命を頂いた私たちは、永遠・普遍に生まれ変わり出変わりし、輪廻転生の肉体的生存の体験・経験を通して自己固有（個有）の生命霊（心・魂）を作り上げているのです。',
                },
                {
                    title: '肉体的死は終わりではなく新たなる出発',
                    article:
                        '肉体的死とは、「霊界に還って、生きる」という事です。肉体があっても無くても、私たちはずっと生命活動を行なっているのです。肉体をもった生存も、霊的な生存も、全て自己の本質＝「生命」の活動の姿なのです。\n \
                        自己の本質である生命が永遠・普遍であり、地球歴史40億年にわたって生まれ変わり（輪廻転生）を繰り返してきているのだ、という事が悟れますと、肉体的な死に対する不安や苦しみは無くなります。今生の一生も、『大親神大生命』より肉体をお貸し与え頂き、使用させて頂いているのです。人類はこの事が悟れなかった為に、死に対して恐怖心をもち、色々な神仏を作り宗教行なって来たのです。\n \
                        一つの人生（肉体的生命活動）が終わるという事は、また新たなる肉体的誕生と一生の出発を意味します。新たなる肉体的生命活動を行う＝誕生させて頂く場所は、この地球上だけに限りません。この銀河宇宙のどこの星にでも、大宇宙のどこの銀河にでも誕生する事は可能なのです。',
                },
            ],
        },
        {
            rule: '生命霊（心・魂）と肉体',
            hasLink: false,
            mainArticle:
                '『大親神大生命』の大いなる生命のお力・お働きにより、私たち全ての生命の御子らの生命霊（心と魂）は、この世＝親物質現象界に肉体的誕生を行い、人生のいろいろな体験・経験をさせて頂いております。',
            articles: [
                {
                    title: '肉体的生存',
                    article:
                        '生命霊（心と魂）が肉体を持って親物質現象界（この世）に活かされ生きる事を、「肉体的生存」と言います。\n \
                        生命霊は肉体的生存によって、色々な生存の味わいを実際に体験・経験し、少しずつ自己の生命霊観念を成長発展させてゆくのです。',
                },
                {
                    title: '霊的生存',
                    article:
                        '肉体を土にお返しした後、「親命霊界（霊界）」で霊的に生きる事を「霊的生存」と言います。\n \
                        \n \
                        肉体的生存によって色々な人生の体験・経験をする事は、霊的生存にとって必要不可欠な事です。霊界には物質が一切無く、生命霊には肉体が有りません。ですから、色々な生存の味わいを具体的に体験・経験する事が出来ません。\n \
                        \n \
                        例えば、リンゴという果物の味わいは、肉体をもって実際にリンゴを食べてみなければ分かりません。リンゴの味わいを体験するためには、肉体が必要であり、そしてリンゴという物質が必要なのです。\n \
                        つまり、親物質現象界の肉体的生存は、生命霊が実際の味わいを体験・経験して、自己の生命霊観念として収穫するためなのです。\n \
                        \n \
                        『大親神大生命』は私たち生命の御子の生命霊に色々な味わいを体験・経験させて下さるために、親物質現象界を創造して下さり、肉体を与えて下さったのです。\n \
                        \n \
                        親命霊界（霊界）において生命霊が霊的生存だけを続けていては、新たな生存の味わいを生命霊観念として収穫する事は出来ません。つまり、自己の生命霊観念を拡大して霊的成長発展をする事が出来ないのです。',
                },
            ],
        },
        {
            rule: '「大自然の真理法則」という交通法規と人間自動車',
            hasLink: false,
            mainArticle:
                '大自然の営みには、一定の秩序があります。この秩序を「大自然の真理法則」と言っております。「大自然の真理法則」は、大自然を行って下さっている『大親神大生命』が、大自然の全ての営みを行う為に定めた絶対的な決まりであり、掟です。\n \
                \n \
                この世は、大自然の真理法則によって創られておりますので、大自然の真理法則が通用しないものはこの世にありません。私たちの運命や環境の全て、この世の事柄の全てに通用しております。\n \
                自分の運命や環境に働く「大自然の真理法則」を悟らずに人生を生きることは、交通法規を分からずに自動車を運転するのと同じことです。\n \
                \n \
                人生街道という例えで考えるならば、心（魂）が運転手で、肉体は乗り物、すなわち自動車です。私たちは、人間という名の自動車を、大自然の生命の大親『大親神大生命』よりお与え頂き、人間自動車を運転して自分の人生街道を走っているのです。人生街道を運転するためには、人生街道に働いている「大自然の真理法則」という交通法規を悟っておかなければなりません。\n \
                \n \
                交通法規を守って、全員が事故を起こさないように、出来るだけ調和して運転するからこそ、楽しいドライブと安全で円滑な交通が成り立ちます。「大自然の真理法則」のルールに沿って、全員が調和して、平和に楽しく生きるのが本来の姿です。お互いの人間自動車でぶつけ合って、争ったり殺し合ったりするのは間違いです。\n \
                \n \
                交通法規を無視してルール違反をすれば、事故を起こしたり、交通違反で逮捕されたり、罰金を支払わされたり、色々と問題が起こります。同様に、「大自然の真理法則」のルール違反をすれば、様々な人生の事故が起こったり、たまたま運悪くマイナスの事に遭遇したりして、運命・環境など色々な人生問題で躓く結果となるのです。',
            articles: [],
        },
        {
            rule: 'おつなぎ徳積みの理',
            hasLink: true,
            mainArticle:
                '「おつなぎ徳積み」とは、『大親神大生命』に対し奉り、生存の喜びと感謝の念をもって、世のため人のために、自らの能動的意志で行うプラスの行いです。\n \
                世のため人のために行ったプラスの種が、「循環・還元の理法」によって結局、自分の運命や環境に「徳」となって大きく巡ってくるのです。「おつなぎ徳積みの理」は、他人にも自分にもプラスに循還してゆく大自然の真理法則の一つです。',
            articles: [
                {
                    title: '徳積み＝生命財産の貯蓄',
                    article:
                        '徳とは、生命に蓄積する財産です。より良く生きてゆくために必要な、自分自身の生命の力働です。人間社会では、お金が全く無ければ、人間社会で生きて行く事は出来ません。同じように、徳という生命財産が無くなると、私たち生命の御子は、大自然界で生きる事が出来なくなります。\n \
                        \n \
                        徳とは、この世＝親物質現象界で、他の生命のために良い事をした分だけ、『大親神大生命』が生命の御子らの生命につけて下さる財産です。\n \
                        植物や動物は、自分の体を他の生命に食べてもらい、他の生命の役に立つ事によって、少しずつ生命財産を貯めていきます。人間の場合は、人のため・世のため、全生物のため、地球全体のために良い行いをする事によって、『大親神大生命』が生命財産＝徳をつけて下さいます。',
                },
                {
                    title: '「徳」と「お金」の違い',
                    article:
                        '「徳」とは『大親神大生命』が認めて下さった生命の財産です。\n \
                        生命そのものの財産ですから、永遠普遍の価値であり、いつでも・どこでも・何にでも通用します。今世だけではなく、来世にも、再来世にも通用します。親物質現象界（この世、物質・肉体の世界）だけではなく、霊界（心・魂の世界）にも、生命界（生命の世界）にも通用します。\n \
                        \n \
                        一方、人間社会の「お金」は、人間が認めた財産です。\n \
                        人間同士が認め合って成り立っているお金は、人間が認めている時にだけ通用する刹那的な価値です。この世の人間社会の中でしか通用しませんし、時代や政策などによって価値が変化したり、通用しなくなったりします。\n \
                        \n \
                        また、徳とは、より良く生きるための“全て”に通用する価値です。\n \
                        徳があれば、運命・環境を良くする事も、あらゆる人間関係を良くする事も、人や物との縁を良くする事も、才能や能力を開発発展する事も、よりプラスの観念や感情を保って生きる事も出来るのです。\n \
                        \n \
                        しかし、人間のお金がいくらあっても、運命が必ず良くなる、という事はありません。運命・環境は人間のお金で買うことは出来ません。人間のお金は、『大親神大生命』が認めて下さった生命の財産ではないからです。運命・環境は『大親神大生命』がそれぞれの徳の分だけ、生命の御子にお与え下さるものなのです。',
                },
                {
                    title: '徳のある生き方と、徳のない生き方',
                    article:
                        '徳があれば、持っている徳の分だけ、美しい心や美しい考えをもって、より良く生きる事が出来ます。毎日、より大きな感謝と喜びをもって、幸せでプラスの人生を生きる事が出来ます。他に幸せや喜びを与えるプラスの生き方を行って、ますます徳を高めていきます。\n \
                        \n \
                        徳が無いと、人を羨んだり、憎んだり、嫌悪感をもったり、運命や環境に不足不満を持ったりして、悩み苦しむ不幸な人生を送る事になります。お金があっても徳が無ければ、かえってお金に執着し、失う事の不安に苛まれ、マイナスの人生や生き方になってしまうのです。\n \
                        \n \
                        \n \
                        現代の地球人は、科学文明の発達によって物質的には豊かになりましたが、徳が無いために、より良く生きる事が出来ていません。\n \
                        <ul class="disc-list-ul">  \
                            <li class="disc-list-li"> クレジットカードなどの技術を発達させればさせるほど、詐欺や犯罪が増えて、かえって不便で不安な生活を送っています。</li> \
                            <li class="disc-list-li"> "争いや戦争などに無駄なお金やエネルギーをかけてお互いに殺し合っています。</li> \
                            <li class="disc-list-li"> 国家予算を赤字にしてまでも軍隊を持ち、軍事兵器の開発戦争をしています。</li> \
                            <li class="disc-list-li"> 金を奪い合う経済競争を世界中で行い、金の変動相場制や株式の売買によってマネーゲームを行い、貧富の差を広げています。</li> \
                            <li class="disc-list-li"> 無駄なエネルギーと資源を消費して、地球を破壊し他の生物をどんどん絶滅させています。</li> \
                        </ul>  \
                        徳が無いと、他に苦しみを与えるマイナスの生命活動を行って、ますます徳を失ってしまうのです。\n \
                        \n \
                        この世＝親物質現象界を生きる目的は、「徳」を高めるためです。\n \
                        私たち人間は、銀行にお金を貯める事は一生懸命にやってきました。しかし、生命の貯金＝徳を高める事は、生命をかけて一生懸命にやった事はありません。「徳」という事が分からなかったからです。\n \
                        \n \
                        もし「徳」という生命財産を人類が持つ事が出来れば、お互いに助け合い、より良く生きていく事が出来るのです。「おつなぎ徳積みの理」は、自分自身にとっても、地球全体にとっても、絶対に必要不可欠な事なのです。',
                },
                {
                    title: 'おつなぎ＝「繋ぎの理」',
                    article:
                        'おつなぎとは「繋ぎの理」です。「おつなぎ徳積み」を行わせて頂くと、『大親神大生命』の御親縁 <sup class="smaller">※</sup> を頂く事が出来ます。（ <sup class="smaller"> ※ </sup> 御親縁とは、「生命の大親」との御縁です。）\n \
                        \n \
                        徳とは、より良く生きて行くために必要な「生命の力働」です。\n \
                        『大親神大生命』の大いなる生命のお力・お働きによって、運命・環境など、あらゆる現象・事柄が現わされています。『大親神大生命』のお力・お働きの一部を生命力働＝徳として頂くからこそ、私たちは、より良く生きて行く事が出来るのです。\n \
                        『大親神大生命』のお力・お働きを頂くためには、御親縁を頂く事が絶対に必要不可欠なのです。',
                },
                {
                    title: '「陰徳の理」',
                    article:
                        '『大親神大生命』は御自親の宣伝をなされずに、私たち全ての生命の御子らを生かして下さっておられます。これを「陰徳の理」と言います。\n \
                        \n \
                        種を土に蒔くと、土に隠れて分かりませんが、種はやがて芽を出して成長し、花を咲かせて実を稔らせます。見えるように種を蒔いたのでは、鳥に食べられてしまうか、風で吹き飛んでしまいます。\n \
                        せっかく良い事をさせて頂いても、「自分はこれだけ良い事をしたんだ」と自分の行いの宣伝をしたら、その分だけ、誠は減ってしまうのです。\n \
                        \n \
                        「おつなぎ徳積みの理」を行う時に自分の名前を書くのは、理を悟っていない証拠となります。人間は、人に判ってもらおうとして、のし袋に名前を書きますが、これでは人間事になって、『大親神大生命』に対し奉り行う事にはなりませんので、注意してください。',
                },
            ],
        },
        {
            rule: '「食べる」事の意味 -食物連鎖の理-',
            hasLink: false,
            mainArticle:
                'この世（親物質現象界）で肉体をもって生きていく上で、「食べる」という事は必要不可欠であり大事なことです。では「食べる」という事には、どのような意味があるのでしょうか？『大親神大生命』は何の為に「食物連鎖の理」という仕組みをお創り下さったのでしょうか？',
            articles: [
                {
                    title: '「食物連鎖の理」とは、生命が生命を支え合うプラスの仕組みです',
                    article:
                        '「食物連鎖の理」とは、生命と生命がお互いに支え合い、助け合い、プラスをし合う仕組みの事です。『大親神大生命』お創り下さった この仕組みに従って、全生物が、それぞれの生命の役割をもって、全体の役に立って生きています。\n \
                        植物は一次生産者として、自分の体を作り上げ、その体を草食動物に提供します。そして今度は、草食動物が二次生産者となって、植物を食べて自分の体を作り上げ、肉食動物に提供します。全ての生物が、他を生かす為に自分の体を提供しています。\n \
                        この「食物連鎖の理」という素晴らしい生命の助け合いを、「弱肉強食」とマイナスに捉える事は間違いです。例えばライオンはシマウマを殺して食べてしまいます。しかし食べられたシマウマの生命は、ライオンを生かす力となります。これは、人間が行う無益な殺戮とは違います。それに、ライオンは人間と違って、必要以上に狩ったりはいたしません。また、ライオンがシマウマを食べて間引きしなければ、数が増えすぎて、かえってシマウマが生きていけなくなってしまいます。全体のバランスを取るために、肉食動物が存在しているのであり、草食動物が食べられる事は、お互いに生きていく為の一つのプラスであると言えます。',
                },
                {
                    title: '生命財産（徳）を高め、生命霊（魂）を成長発展させる為の仕組みです',
                    article:
                        '「食物連鎖の理」によって、全生物が、他の生物に食べられて役に立っています。他を生かす為に自分の体を捧げるというのは、プラスの行いです。プラスを行った分だけ、『大親神大生命』は生命財産（徳）を授けて下さいます。「食物連鎖の理」とは、私たち生命の御子が生命財産を高め、生命霊（魂）の成長発展を行えるよう、『大親神大生命』がお作り下さった素晴らしい仕組みなのです。\n \
                        この地球上で40億年にもわたり生命現象が続いているのは、全生物がそれぞれ、植物は植物として、動物は動物として、生命の仕事をしているからです。その事によって、生命霊（魂）が成長発展していくと同時に、他の役に立つ事で、生命財産も高まっていくのです。この仕組みがなければ、例えば野菜は徳を積む事ができません。野菜の生命は、自分の体を他の生物に食べられることによって他のお役に立った事となり、徳を積むことが出来るのです。\n \
                        生命財産が高まると、段々と植物から、昆虫など少し動ける生物へというように、次の段階の生物の一生を『大親神大生命』よりお与え頂く事が出来ます。そうして一次生産者から二次生産者、二次生産者から三次生産者へ、より高い段階の生物の役に立つようになってゆく事が「生命霊（魂）の成長発展」です。より高いプラスをした分だけ、『大親神大生命』より生命財産を頂いて、より高い段階の生き方が出来るようになるのです。',
                },
                {
                    title: '「食物連鎖の理」における人間の役割',
                    article:
                        '人間以外の全ての生物は、食物連鎖の中で、他の生物に食べられて役に立っています。けれども人間だけが、誰からも食べられることはありません。食物連鎖の頂点にいる人間は、全ての生物の生命に支えられているのですから、他の全生物の役に立つべきです。\n \
                        しかし現在、全生物の中で人間だけが、他の生命の為に何の役にも立っていません。それどころか、「グルメ」といって自己満足の為に我欲で食べたり、金儲けの為に他の種を絶滅させたり、食物連鎖の生態系を壊したりしています。日本では売れ残りや期限を超えた食品、食べ残しなど、本来食べられたはずの食糧が年間643万トンも棄てられています。それだけの生命を無駄にしているのです。\n \
                        では人間は、「食物連鎖の理」において、どうすれば全生物の役に立てるのでしょうか？\n \
                        \n \
                        <b class="bold"> \
                            <i class="underline">☆『大親神大生命』のお役に立つ</i> \
                        </b>\n \
                        食物連鎖の頂点にいる人間は、様々な生物を食べて生きています。一番何でも食べてしまうのですから、人間は万物のお役に立って、プラスの生存活動を行わなければなりません。つまり、『大親神大生命』のお役に立って生きるという事が、人間の生命の役割なのです。\n \
                        \n \
                        <b class= "bold" > \
                            <i class="underline">☆人間の本当の食べ方　気持ちを与える</i> \
                        </b>\n \
                        種類分化で色々な物を食べて色々な個有性ある味わいを命霊観念に収穫する事が出来る。牛は草をよく食べるので、草の感覚しか判らない。そこで人間が牛を食べる事によって、より幅の広い、味わいある、高い命霊観念を与える。これが「相互支合の理」だ。このようにして「食べる」という事は本当は大きなプラスなのだ。生命霊同士が高め合う為に\n \
                        大親大生命は「食べる」という現象を行わせて下さっているのだ。',
                },
                {
                    title: '食べるとは、肉体と同時に気持ちも一体になる事',
                    article:
                        '食べ物とは「生命霊食物」だ。牛は牛肉になる迄の間に、一生懸命草を食べて一頭の牛になるまで身体を育てた牛として生きた一生がある。その時に、牛は牛の気持ちとしてそこまで通ってきた。したがって生命霊としての気持ちがある。「食べる」という事は、向こうの身体をこちらの身体の中に入れてしまって一つの身体になるという事だ。その時に、身体を一体にする事で気持ちの方も一つになるべきなのだ。\n \
                        \n \
                        自分が飼っていた家畜を食べるとなれば、一体感を持って「あなたと共に生きていきます」という気持ちになるはずだ。それを更に、自分が直接飼っていたとか飼っていないという事を超えて、生命霊同士の愛情や一体感を持って頂くのが本当の頂き方である。\n \
                        食べると、食べられた（例えば牛の）生命霊と自分の生命霊が「親性一体全体　親調和　親秩序の理」となる。\n \
                        \n \
                        何かを食べたら、それは自分の体の一部になっていく。\n \
                        気持ちも、自分の意識の一部になっていく。\n \
                        気持ちの交換交流。牛とは、人対人のようには会話が出来ないから、「食べる」という行為でお互いに気持ちを受け取るのだ。気持ちを受け取って一体化する事が「食べる」という事なのだ。「食べる」という形を通して気持ちの繋がりが発展していき、生命霊の家族を創るのだ。',
                },
                {
                    title: '食べた側が、食べられた側にプラスを与えるのが本当の「食べ方」',
                    article:
                        '大自然の理に適った食べ方があります。こちらが共に「真性一体になって、相手が高まってください」という親心を持って食べる事です。\n \
                        \n \
                        相手は身体を肉として提供してくれた。したがって牛の肉を頂く時には、牛の気持ちよりも人間の気持ちの方が上であるから、「一体の理」としてこちらは相手の生命霊が高まるようにプラスの高い理次元・理段階の気持ちを与える。その「一体感」が「食べる」という事なのだ。\n \
                        \n \
                        「食べる」とは身体を一体化する事であり、そうして生命霊を互いに高め合っていく事なのだ。食べること自体が生命の御仕事なのだ。\n \
                        \n \
                        「生命霊食物の理」とは「親性一体全体　親調和　親秩序の理」に沿い奉り、身体を一つにするという生存行為を通して、生命霊を真成長発展をする為に\n \
                        大親大生命がお作り下さった制度だ。',
                },
                {
                    title: '今の地球人の食べ方',
                    article:
                        '人間はこのような意味が全く判らなかったので、「グルメ」「まだピクピク動いていて生きがよい」などと自己満足の食べ方しかして来なかった。しかしそのような自己中心・我欲で食べるのは間違いだ。例えば日本だけでも650万トンもの食料を廃棄している。\n \
                        \n \
                        その時には、「自分の身体と一体となって共に生きていこう」「一体だよ」という気持ちでその身体を頂く。',
                },
                {
                    title: 'よく噛む事の大切さ',
                    article:
                        '　焼き肉に連れて行って以来、ただ胃袋にたくさん流し込むという感じになってしまって、「よく噛みしめて」の部分がなくなってしまった。そこで、これからはこれを必ず一人ずつ真拝読してから「生命霊食物　真拝命拝霊拝受の理」の真御挨拶を行うのだ。子供達など、いくら胃袋に流し込むかと、量を問題にしていた。量ではなく、質だ。いかにそれだけ高い意識で頂くか、が大事だ。質が大事だ。気持ち対気持ちをプラスにする為に「食物連鎖の理」がある。これからはこのような理を悟って、よく噛んで味わって頂きなさい。',
                },
            ],
        },
        {
            rule: '幸福の本質　-幸せとは何か？-',
            hasLink: true,
            articles: [
                {
                    title: '幸せになろうとするのが、生命の本質的な方向性',
                    article:
                        '誰にでも「より幸せになろう」「より良く生きよう」という本質的な欲求があります。\n \
                        より幸せになろう、より良く生きようというのは、『大親神大生命』が生命あるものにお与え下さった、本質的な生命の方向性なのです。ですから、誰でも、生命ある全てのものは、より良くなっていく力を持っているのです。\n \
                        \n \
                        虫一匹をとっても、より多く餌を探して、子孫を繁栄させようとしています。植物も、栄養や水分がある方へ根を伸ばし、光のある方へ枝や葉を伸ばしていきます。これは「より良く」生きようとしている姿だといえます。\n \
                        人間も国、言葉、風習、食べ物、生活習慣などは違っていても、「幸せになりたい」という欲求は、世界中のどこの人であろうとも共通です。',
                },
                {
                    title: '幸せとは何か？',
                    article:
                        'では、幸せとは何なのでしょうか？\n \
                        物質的に恵まれていること＝より幸せである、ということにはなりません。お金や物がどれほど沢山あったとしても、それを自分が「幸せだ」と感じなければ、その人に幸せはありません。\n \
                        \n \
                        例えば現代は、江戸時代に比べて物質的に豊富で恵まれた時代です。文明が発達して生活は便利になりました。では、現代人は“幸せ”で、テレビも自動車も無い江戸時代の人々は“不幸”だったと断言できるでしょうか？\n \
                        むしろ人生という意味では、毎日ストレスを溜めて、生きる事の不安や悩み・苦しみに圧迫されながら暮らす現代人の方が、“不幸な人生”であるとも言えます。\n \
                        \n \
                        現代人は、こんなに物が溢れて生活が便利になっても、「あれが足りない、これが足りない」と言って不足不満で生きています。感じ方がマイナスで暮らしているのですから、幸せが有るわけがありません。\n \
                        幸せというのは、その人が幸せと感じなければ、永遠に幸せは無いのです。幸せとは、その人自身の実感によって決まるのです。',
                },
                {
                    title: '感じ方によって幸せにも不幸にもなれる',
                    article:
                        '分かりやすい例として、食べ物の好き嫌いで考えてみましょう。\n \
                        納豆の好きな人は、納豆を食べて「美味しい！」と感じます。しかし納豆が嫌いな人は、同じ納豆を食べても、全く美味しく感じられません。では、納豆は“美味しい”のでしょうか、“不味い”のでしょうか？納豆は納豆の味にしか過ぎません。問題は、それをどう感じるかということなのです。\n \
                        \n \
                        幸せというのも、その人が自分の運命・環境をどう感じるか、ということなのです。\n \
                        同じ家に生まれても、姉は「素晴らしい両親の許に生まれて良かった、幸せだ」と感じて日々を送っていたとします。一方、妹は「こんな親を持って不幸だ、毎日が辛い」と思って生きていたとします。\n \
                        他人からみれば、同じ環境なのですが、その家庭をどう感じるかで姉と妹の「幸福感」は全く違うのです。同じ運命・環境でも感じ方によって、幸せにも不幸にもなれるのです。\n \
                        \n \
                        「幸福の本質」というのは、実は自分の心の方にあったのです。運命・環境、人間関係、それをどう感じるかは自分の心次第なのです。',
                },
                {
                    title: '心の自由選択性',
                    article:
                        '私たちの心は、同じ事を、どのようにでも感じる事ができます。『大親神大生命』は、私たちがどんな気持ちでも感じられるように、心の自由選択性という能力をお与え下さっています。そして心には形がないので、いくらでも無限の気持ちを持つ事ができるのです。\n \
                        \n \
                        無限に素晴らしい気持ちを持てるのですから、同じ運命・環境でも、プラスに感じる事が出来れば、幸せはどんどん大きくなるはずです。せっかく自由選択性を頂いているのですから、マイナスに感じるよりも、プラスに感じていく方が絶対に得です。\n \
                        \n \
                        しかし地球人類は残念なことに、マイナスの気持ちの方をわざわざ選択していることが多いようです。嫌だと感じれば感じるほど、辛くなって苦しむのは自分自身です。不足不満、不安、嫌悪感、苛立ちなど、マイナスを感じて生きていくのは、損な生き方なのです。',
                },
                {
                    title: '人生はプラスの気持ちの収穫の為にある',
                    article:
                        '人が何かをするのは、喜びや楽しみ・味わいというプラスの気持ちを得る為です。\n \
                        <ul class="disc-list-ul">\
                            <li class="disc-list-li"> 旅行に行って良かった。 </li>\
                            <li class="disc-list-li"> 美味しいものを食べて良かった。 </li>\
                            <li class="disc-list-li"> この仕事をして良かった。 </li>\
                            <li class="disc-list-li"> この人と結婚して良かった。 </li>\
                            <li class="disc-list-li"> 一緒に暮らして良かった。 </li>\
                            <li class="disc-list-li"> 今日一日生きて良かった。 </li>\
                        </ul> \
                        どんな事でも、最終的に「良かった」というプラスの気持ち（幸福感）を収穫する為に行なっているはずです。\n \
                        \n \
                        同じように、私たちが人生を生きるのは「生きて良かったな」という気持ちを収穫するためです。私たちは、生命霊（心・魂）に素晴らしい味わいを収穫するために生きているのです。\n \
                        『大親神大生命』は、この世＝親物質現象界に私たちを誕生させて下さり、肉体的生存をさせて下さっています。この肉体的生存を通して、最大・最高の生きる感動を味わわせて下さるためなのです。\n \
                        \n \
                        それにも拘わらず、せっかく一生を生きてマイナスの気持ちの収穫しかなかったら、それは価値のない人生になってしまいます。しかし、私たちはそのことに気付いていないので、自分のマイナスの感情に流されて日々を生きてしまっているのです。',
                },
                {
                    title: '親感謝させて頂くと、幸せは無限に大きくなる',
                    article:
                        '『大親神大生命』に対し奉り、こうやってこの世＝親物質現象界で日々を通らせて頂いていることに親感謝 <sup class="smaller"> ※ </sup> させて頂き、生きる喜びを感じて毎日を送れたらどんなに幸せでしょうか。心には無限性があるのですから、その幸せはどんどん大きくなるはずです。\n \
                        （ <sup class="smaller"> ※ </sup> 親感謝とは、生命の大親である『大親神大生命』に対し奉りの感謝の気持ちです。）\n \
                        \n \
                        どれだけ大きな幸せを持てるかということは、自分の実生活の中で、どれだけ多くの喜びと親感謝を感じて毎日を生きられるか、にあります。\n \
                        今日一日、心臓を順調に動かして頂いたこと、一家が無事に活かされ生きる事が出来たこと――今まで見落として来た日々の様々なことに、より大きな親感謝と喜びを感じることこそ、幸せの本質なのです。\n \
                        \n \
                        今日一日生きるこの瞬間を「素晴らしい」と感じたら、幸せな気分になります。それをもっと、もっと開発していくのです。喜びと親感謝を感じる力が高まれば、どのような人生、どのような運命・環境であっても、最大・最高・最善の「幸せ」の実感をもって、プラスに生きることができるのです。\n \
                        \n \
                        一人でも多くの人が幸せを感じて喜びで生きられるようになれば、地球は幸せな人々が住む星になっていくのです。',
                },
            ],
        },
        {
            rule: '親子・夫婦の霊的縁',
            hasLink: false,
            mainArticle:
                '『大親神大生命』は、親子・夫婦という役割を、私たちの生命霊（心・魂）の成長発展のためにお作り下さいました。そして、親子・夫婦の組み合わせも、生命霊の成長発展のために、『大親神大生命』がその様にわざわざお決め下さったのです。\n \
                \n \
                「種類分化の理法」によって、色々な人が、それぞれの特色ある考え方や感じ方を持っています。色々な個性の違いを尊敬し合い調和を保つためには、より大きく、より本質的な考えが必然的に必要になります。この事が悟りであり、生命霊の成長発展です。\n \
                \n \
                生命霊の成長発展の為には、今まで自分が持っていなかった意識や考え方・感じ方・気持ち（生命霊観念）を、自分の中に吸収する事が必要不可欠です。\n \
                このような親子・夫婦の組み合わせになっている理由は、『大親神大生命』が、私たちの生命霊の成長発展に必要な「生命霊観念」を与えて下さるためなのです。',
            articles: [
                {
                    title: '最も必要な、最高・最善の組み合わせ',
                    article:
                        '親子・夫婦の縁は、それぞれにとって、最も必要な生命霊観念の相手と組み合わされます。“最も必要な生命霊観念の相手”とは、自分が持っていない生命霊観念を持っている相手です。\n \
                        \n \
                        自分と全く違う相手と色々な体験・経験をする事により、お互いに自分が持っていない気持ちを発見し合い、吸収する事が出来ます。違った人間、つまり違った生命霊と接触するチャンスがなければ、新しい気持ち・違った意識を吸収する事は出来ません。\n \
                        \n \
                        この素晴らしい事を、人間は「性格が違うので、やりにくい」「自分とは全く反対だ」と言って、マイナスに感じてしまう傾向があります。\n \
                        しかし実は、自分の持っていない生命霊観念（考え方や感じ方）こそ、自分に最も必要な霊的栄養素なのです。',
                },
                {
                    title: '親子・夫婦の縁は最大のチャンス',
                    article:
                        'あらゆる人間関係の巡り会いは全て、『大親神大生命』が大きな生命愛によってお与え下さったものです。親子・夫婦をはじめ、兄弟、友人、知人、同僚など、人生には様々な人間関係があります。\n \
                        \n \
                        その中でも特に「親子・夫婦」は一番身近な縁です。最も近く、一緒に、人生を通らざるをえない縁です。だからこそ、親子・夫婦の縁は、生命霊の成長発展のために『大親神大生命』がお与え下さった、最大・最高・最善のチャンスだと言えます。\n \
                        \n \
                        人生の色々な道中を共に生きて、プラスをし合ったり積極的に語り合ったりしている間に、気持ちと気持ちがつながっていきます。そして、お互いに自分が持っていない気持ちを発見し合い、また、それを共有し合う事で、どんどん生命霊観念を広げていく事が出来るのです。\n \
                        \n \
                        したがって、親子でも夫婦でも、お互いに良い関係をいかに築くか、という事が大切です。妻の気持ちを抱えて大事にして通る事ができなければ、夫でも何でもありません。逆もまた同様です。お互いに相手を認め合い、尊敬し、感謝してみましょう。必ずプラスの関係になります。\n \
                        \n \
                        どうしても相手に感謝の気持ちをもてない、プラスに思えない、と悩んでいる方も沢山いらっしゃると思います。そのような時には、何よりも先ず『大親神大生命』に対し奉り「ありがとうございます」と親感謝をさせて頂きましょう。そして『大親神大生命』にお受取り頂けますように、という気持ちで、真心でプラスをし続けてみて下さい。\n \
                        『大親神大生命』は、真心で努力したら必ずお受け取り下さります。『大親神大生命』がお受け取り下さると、人間関係がプラスに変わる、という現象を必ず起こして下さるのです。',
                },
            ],
        },
        {
            rule: '目に見えない生命の力と働き',
            hasLink: false,
            mainArticle: '目に見えない生命の力と働き：內文',
            articles: [
                {
                    title: '目に見えない生命の力と働き',
                    article: '目に見えない生命の力と働き：內文1',
                },
                {
                    title: '目に見えない生命の力と働き 2',
                    article: '目に見えない生命の力と働き：內文2',
                },
                {
                    title: '目に見えない生命の力と働き 3',
                    article: '目に見えない生命の力と働き：內文3',
                },
            ],
        },
    ]

    return { doctrineIntro, doctrineArticles, doctrineRules }
}
